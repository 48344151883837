import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, PLATFORM_ID, Signal, ViewEncapsulation, computed, inject, signal } from '@angular/core';
import { ENVIRONMENT } from '@environment';
import { TrackingService } from '@usheru-hff/shared/utils-tracking';

/**
 * Usheru logo used in the {@link FooterComponent}.
 */
@Component({
  selector: 'ush-usheru-logo',
  templateUrl: './usheru-logo.component.html',
  styleUrls: ['./usheru-logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsheruLogoComponent implements OnInit {
  private platformId = inject(PLATFORM_ID);
  private env = inject(ENVIRONMENT);
  private trackingService = inject(TrackingService);

  linkSig!: Signal<string>;
  /**
   * Query params to add to the redirection
   */
  utm = signal<string>('');

  private isBrowser: boolean;

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
    this.buildLink();
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.utm.set(`?utm_source=client-site&utm_medium=powered-by-button&utm_campaign=${window.location.href}`);
    }
  }

  buildLink() {
    const baseUrl = 'https://www.usheru.com';
    let section = this.env.appType === 'widget' ? 'select' : 'connect';
    this.linkSig = computed(() => `${baseUrl}/${section}/${this.utm()}`);
  }

  onPoweredByClicked() {
    this.trackingService.trackEvent({
      action: 'PoweredBy',
      properties: {
        category: 'Footer',
        label: 'usheru'
      }
    });
  }
}
