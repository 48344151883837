import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsheruLogoComponent } from './usheru-logo/usheru-logo.component';
import { SharedUtilsTranslationModule } from '@usheru-hff/shared/utils-translation';

@NgModule({
  imports: [CommonModule, SharedUtilsTranslationModule],
  declarations: [UsheruLogoComponent],
  exports: [UsheruLogoComponent]
})
export class HffCommonUiUsheruLogoModule {}
